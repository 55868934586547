const INFO = {
	main: {
		title: "Stephen Gray - Portfolio",
		name: "Stephen Gray",
		email: "stephengray7505@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/cassinihx",
		linkedin: "https://linkedin.com/in/stephengray7505",
		instagram: "https://instagram.com/freethecloudy",
		stackoverflow: "https://stackexchange.com/users/4122842/stephen-gray",
	},

	homepage: {
		title: "Full-stack developer, Cyber Security and UX enthusiast, Student. Welcome to my React JS portfolio site.",
		description:
			"With experience as a Systems Administrator and Engineer, I collate expertise in React, JavaScript, Node.js, SQL, Powershell, BASH, HTML, and CSS to create innovative, scalable solutions. Passionate about leveraging technology to streamline systems and drive efficiency, I thrive on tackling complex challenges.",
	},

	about: {
		title: "I'm Stephen, a Bristol based technology enthusiast.",
		description:
			`I've worked on a range of projects over the years. From migrations from G Apps (Now Google Workspace) to Office 365, Information Security management, ISO27001 implementation, Database migration / Data conversion, CDN administration (RHEL7, Cloudflare, AWS, IBM DB2), maintenance and troubleshooting. I have a broad professional skillset.
			\n
			In my spare time I can be found working with Emmaus, an NGO seeking to end homelessness, persuing my own coding projects, working on my car or enjoying some off-time with a good film.`,
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Unity 3D prototype - 'WE ARE ALL HUMAN'",
			description:
				"A prototype museum exhibit built around embodied interactions, designed to challenge users parasocial relationships.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
			openInPopup: true, // Custom key to identify popup behavior
			linkText: "Windows build .ZIP Download",
			link: "https://media.stephen-gray.co.uk/Build3.zip", // Path to Unity game in public folder		
			secondLinkText: "WebGL Build",
			secondLink: "https://cassinihx.github.io/UnityInteractiveExhibit/",
		},
		{
			title: "3.js portfolio",
			description:
				"Please take a look at my Three.js portfolio built as a proof of concept for my BSc.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://codesandbox.io/p/devbox/sem1-project-final-q8733m",
			// secondLinkText: "Github Repo Test",
			// secondLink: "https://github.com/example/repo",
		},

		{
			title: "p5.js portfolio",
			description:
				"Early first year p5.js work",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://cassinihx.github.io/p5-Y1-project/projectPrimaryAnimated%20Final/index.html",
			// secondLinkText: "Github Repo Test",
			// secondLink: "https://github.com/example/repo",
		},

		{
			title: "Unity game - C#",
			description:
				"Assets compiled in Adobe Maya and code compiled using C# within the unity engine.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
			openInPopup: true, // Custom key to identify popup behavior
			linkText: "View Project",
			link: "https://cassinihx.github.io/UnityGame/", // Path to Unity game in public folder		
			// secondLinkText: "Github Repo Test",
			// secondLink: "https://github.com/example/repo",
		},

		// {
		// 	title: "Project 3",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
