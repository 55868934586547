import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./Carestream.png"
								alt="facebook"
								className="work-image"
							/>
							<div className="work-title">Carestream Dental</div>
							<div className="work-subtitle">
								Engineer
							</div>
							<div className="work-duration">2019 - 2022</div>
						</div>

						<div className="work">
							<img
								src="./IDI.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Innovative Document Imaging</div>
							<div className="work-subtitle">
								IT Manager
							</div>
							<div className="work-duration">2017 - 2019</div>
						</div>
						<div className="work">
							<img
								src="./CW.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Challenges Worldwide</div>
							<div className="work-subtitle">
								Volunteer Consultant
							</div>
							<div className="work-duration">2016 - 2017</div>
						</div>
						<div className="work">
							<img
								src="./PSI.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">PSI International</div>
							<div className="work-subtitle">
								Systems Administrator
							</div>
							<div className="work-duration">2014 - 2016</div>
						</div>
						<div className="work">
							<img
								src="./SL.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">ServiceLine</div>
							<div className="work-subtitle">
								Systems Analyst
							</div>
							<div className="work-duration">2013 - 2014</div>
						</div>
						<div className="work">
							<img
								src="./Serco.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Serco Global</div>
							<div className="work-subtitle">
								Service Desk Analyst
							</div>
							<div className="work-duration">2012 - 2013</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
